<template>
  <section id="create-user">
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <!-- email -->
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="MailIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="email"
                  id="email"
                  type="email"
                  placeholder="Email"
                />
              </b-input-group>
              <b-form-text class="text-danger">
                Enter a Valid Email
              </b-form-text>
            </b-form-group>
          </b-col>

          <!-- username -->
          <b-col cols="12">
            <b-form-group
              label="Username"
              label-for="usename"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="username"
                  id="usename"
                  placeholder="Username"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- password -->
          <b-col cols="12">
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-input-group
                class="input-group-merge"
                :class="validation ? 'is-valid' : 'is-invalid'"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="password"
                  v-model="password"
                  type="password"
                  :state="validation"
                  placeholder="Password"
                />
              </b-input-group>
              <b-form-invalid-feedback :state="validation">
                Your password must be 8-20 characters long.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation">
                Looks Good.
              </b-form-valid-feedback>
            </b-form-group>
          </b-col>

          <!-- re-password -->
          <b-col cols="12">
            <b-form-group
              label="Re-Password"
              label-for="re-password"
            >
              <b-input-group
                class="input-group-merge"
                :class="validation ? 'is-valid' : 'is-invalid'"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="LockIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="re-password"
                  v-model="rePassword"
                  type="password"
                  :state="validation"
                  placeholder="Re-Password"
                />
              </b-input-group>
              <b-form-invalid-feedback :state="validation">
                Your password must be 8-20 characters long.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation">
                Looks Good.
              </b-form-valid-feedback>
            </b-form-group>
          </b-col>

          <!-- hubs -->
          <b-col cols="12">
            <b-form-group label="Hubs">
              <v-select
                multiple
                v-model="selectedHubs"
                label="name"
                :options="hubs"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="reset"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormText,
  BFormDatalist,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
} from "bootstrap-vue"
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive"
import axios from "axios"

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BForm,
    BFormText,
    BButton,
    BFormDatalist,
    BFormInvalidFeedback,
    BFormValidFeedback,
    vSelect
  },
 directives: {
    Ripple,
  },
  data() {
    return {
      email: "",
      username: "",
      password: "",
      rePassword: "",
      hubs: [],
      selectedHubs: []

    }
  },
  computed: {
    validation() {
      return this.password.length > 4 && this.password.length < 13
    },
  },
  methods: {
    getAllHubs() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "/hubs/all/", {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          this.hubs = response.data.results.map(item => {
            return { name: item.name, id: item.id }
          })
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    },
    reset() {
      this.email = ""
      this.username = ""
      this.password = ""
      this.rePassword = ""
    },
    submit() {
      axios
        .post(process.env.VUE_APP_ROOT_API + "/users/", {
          email: this.email,
          username: this.username,
          password: this.password,
          hubs: this.selectedHubs.map(item => { return item.id })
        }, {
          headers: {
            "Authorization": `JWT ${this.$store.state.jwt}`,
            "Content-Type": "application/json"
          }
        })
        .then((response) => {
          console.log(response.status)
          this.$router.push({ name: "user-management" })
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({ name: "login" })
          }
        })
    }
  },
  created() {
    this.getAllHubs()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>